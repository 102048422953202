import { graphql } from "gatsby";
import { Layout, Menu } from "antd";
import React, { Component } from "react";

import { Link } from "gatsby";
import "../css/rahul-styles.css";
import { Helmet } from "react-helmet";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactGA from "react-ga";
import PopupForm from "../components/popupForm";
import MainLayout from "../components/layout";

import placeholder from "../images/placeholder.png";

let selectedCategoryName = "All News";

class NewsListing extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogData: this.props.data.allWordpressWpBlog.edges,
			blogTop: this.props.data.allWordpressPage.nodes[0],
			collapsed: false,
			time: 900000000,
		};
	}

	componentDidMount() {


const blogListings = document.querySelectorAll(".blog-listing-sub-heading");

		blogListings.forEach((blogListing) => {
			const text = blogListing.textContent;
			if (text !== "All" && text !== "All Blog") {
			
				const menuItems = document.querySelectorAll(".category-menu li");
				
				const menuItem = Array.from(menuItems).find(
					(item) => item.textContent.trim() == text
				);
				
		
			
				if (menuItem) {
					menuItem.click();
				
				}
			}
		});

	}

	toggle = () => {
		this.setState({
			collapsed:  !this.state.collapsed,
		});
	};

	

	filterTopCategory(i) {
		this.setState({
			collapsed: false,
		});

		let activeClass = document.querySelector(".category-tags .active");
		if (activeClass != null) activeClass.classList.remove("active");

		let currentCatButton = document.querySelector(`#cat-id-${i}`);
		currentCatButton.classList.add("active");

		selectedCategoryName = ReactHtmlParser(this.props.data.allWordpressWpBlogCategories.edges.find((x) => x.node.wordpress_id == i).node.name);

		var filteredRes = [];
		this.props.data.allWordpressWpBlog.edges.forEach((element) => {
			let filterGroupActivity = element.node.blog_categories.filter((value) => {
				return value === i;
			});
			if (filterGroupActivity == i) filteredRes.push(element);
		});
		this.setState({ blogData: filteredRes });
	}
	filterCategory(i) {
		this.setState({
			collapsed: false,
		});
		selectedCategoryName = i === "All" ? "All Blogs" : ReactHtmlParser(this.props.data.allWordpressWpBlogCategories.edges.find((x) => x.node.wordpress_id == i).node.name);
		if (i == "All") {
			this.setState({ blogData: this.props.data.allWordpressWpBlog.edges });
		} else {
			var filteredRes = [];
			this.props.data.allWordpressWpBlog.edges.forEach((element) => {
				let filterGroupActivity = element.node.blog_categories.filter((value) => {
					return value === i;
				});
				if (filterGroupActivity == i) filteredRes.push(element);
			});

			this.setState({ blogData: filteredRes });
		}
	}

	filterCategoryMobile = (event) => {
		const selectedCategoryId = event.target.value;

		const selectedLi = document.querySelector(`.category-menu li[data-value="${selectedCategoryId}"]`);


		if (selectedLi) {
		selectedLi.click();
		}
	  }
	  
	  
	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	render() {
		const { Sider } = Layout;
		const post = this.state.blogTop;
        const metatitle = post.acf.meta_title ;
        const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase; 
		const blogTopID = this.props.data.allWordpressPage.nodes.flatMap(node => node.acf.top_articles.map(article => article.wordpress_id));

		const blogTopNews = this.props.data.allWordpressWpBlog.edges.filter(edge => {
			return blogTopID.includes(edge.node.wordpress_id);
		}).map(edge => edge.node);


const canonical_url = `https://theworldgrad.com${this.props.path}`; 
		return (
			<div>
				<MainLayout>
					<Helmet>
					<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
</Helmet>

					<div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active"> News</li>
										</ol>
				</div>
				</div>
				<div className="container" style={{ overflowX: "hidden" }}>
						<PopupForm time={this.state.time} />
						<div className="col-12">
							<button
								className="btn fixed-enquire-button"
								onClick={() => {
									this.handlePopupWindow();
								}}>
								Enquire
							</button>
						</div>

						<div className="my-1">
							<div className="row  newStyle p-0 newspage">
								<div className="col-12">
									<h1 className="text-muted mb-1">{this.state.blogTop.title}</h1>
									<p className="mb-lg-5 text-muted" dangerouslySetInnerHTML={{ __html: this.state.blogTop.content }}></p>
						
								</div>

 








							</div>
						</div>
						<div className="container bottom-row-blog-listing-container">
							<div className="row bottom-row-blog-listing">
								<div className="col-12 p-0">
								<div className="card all-blog-main-card w-100">
									<div className="card-with-side-menuh">
										
											<div className="col blog-listing-sidebar-container">
											
												<div className="sidebar-container">
													<Layout>
														<Layout>
														
															<div className="col-md-12">
																<div className="blog-listing-card-sub-heading-container">
																	
					

																	<div className="blog-listing-masonry-layout">
																		
																		<div className="blog-listing-wrapper">
																			{this.state.blogData.map(({ node }) => {
																				return (
																					<div key={node.id} className="blog-listing-card-holder">
																						<div className="card main-card-blog-listing mb-3">
																							<div className="blog-image">
																								{node.acf.blog_grid_image != null ? (
																									<Link to={`/study-resources/${node.slug}/`}>
																										<img src={node.acf.blog_grid_image.source_url} className="rounded img-fluid blog-listing-card-image" alt={node.title} />
																									</Link>
																								) : (
																									<Link to={`/study-resources/${node.slug}/`}>
																										<img src={placeholder} className="rounded img-fluid blog-listing-card-image" alt={node.title} />
																									</Link>
																								)}
																							</div>
																							<div
																								onClick={() => {
																									ReactGA.event({
																										category: "Study Resources",
																										action: "Clicks",
																										label: `${ReactHtmlParser(node.title)}`,
																									});
																								}}
																								className="card-body px-0 py-3 blog-listing-card-body">
																								<div className="category-holder">
																									{node.acf.select_category.map((d, index) => (
																										<span key={`${d}-${index}`} className="blog-listing-category">
																											{d}
																										</span>
																									))}
																								</div>
																								<h5 className="card-title blog-listing-card-title blogtitle">
																									<Link to={`/study-resources/${node.slug}/`}>{ReactHtmlParser(node.title)}</Link>
																								</h5>
																								<p className="card-text blog-listing-card-text blogcontent"> {ReactHtmlParser(node.acf.description)} </p>

																								{<p className="text-muted blog-listing-date-text"> - Posted on {node.date} </p>}
																							</div>
																						</div>
																					</div>
																				);
																			})}
																		</div>
																	
																	</div>
																</div>
															</div>
														</Layout>
			
													</Layout>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</MainLayout>
			</div>
		);
	}
}

export default NewsListing;

export const newslisting = graphql`
	query newslisting {
		allWordpressWpBlog(
            sort: { order: DESC, fields: date }
            filter: { blog_categories: { eq: 21 } }
          ) {
            edges {
              node {
                id
                title
                slug
                blog_categories
                wordpress_id
                date(formatString: "DD-M-YYYY")
                acf {
                  description
                  select_category
                  blog_grid_image {
                    source_url
                  }
                }
                featured_media {
                  source_url
                }
              }
            }
          }
		allWordpressWpBlogCategories {
			edges {
				node {
					id
					name
					wordpress_id
					slug
				}
			}
		}

		allWordpressPage(filter: { wordpress_id: { in: 13283 } }) {
			nodes {
				title
				content
				acf {
					top_articles {
						wordpress_id
						post_title
						post_name
						post_date(formatString: "DD-M-YYYY")
					}
meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
					blog_categories {
						name
						slug
						term_id
					}
				}
			}
		}
	}
`;


